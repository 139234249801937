<template>
    <v-dialog v-model="dialog" max-width="700">
        <template v-slot:activator="{ on }">
            <span class="secondary--text subtitle-2" v-on="on">
                <a>{{ truncateText(itemData.command, truncationLength) }}</a>
            </span>
        </template>
        <v-card style="overflow-y: hidden">
            <v-card-title>
                <div class="secondary--text">
                    <v-icon class="mr-1">mdi-script-text-outline</v-icon>
                    {{ title }}
                </div>
            </v-card-title>
            <v-divider class="mb-1"></v-divider>
            <v-card-text>
                <CodeHighlight language="sql" :code="itemData.command" />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <copy-to-clipboard
                    :textToCopy="itemData.command"
                    buttonClass=""
                    buttonColor="primary"
                    :isTextButton="true"
                    buttonName="copy to clipboard"
                    :showIcon="false"
                    :isSmall="false"></copy-to-clipboard>
                <QueryViewResults
                    v-if="
                        itemData.status_name === queryStatusOptions.COMPLETED_QUERY &&
                        itemData.mode_name === queryModeOptions.PREVIEW_MODE &&
                        diffHours(itemData.finished) < 1440
                    "
                    :qid="itemData.qid.toString()"
                    :sql="itemData.command"
                    @queryResultsSuccess="moveToReviewTab"
                    :forDialog="true"></QueryViewResults>
                <v-btn color="secondary" text @click="dialog = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { truncateText } from '@/utils'
import { queryData } from '@/mixins/query'
const CopyToClipboard = () => import('@/components/CopyToClipboard')
const QueryViewResults = () => import('./TheSnapshotQueryViewResults')
const CodeHighlight = () => import('@/components/CodeHighlight')

export default {
    name: 'QueryDialog',
    mixins: [queryData],
    components: { CopyToClipboard, QueryViewResults, CodeHighlight },
    props: {
        title: String,
        truncationLength: Number,
        itemData: Object
    },
    data() {
        return {
            dialog: false
        }
    },
    methods: {
        truncateText: function (text, maxLength) {
            return truncateText(text, maxLength)
        },
        moveToReviewTab() {
            this.dialog = false
            this.$emit('queryResultsSuccess', { value: true })
        }
    }
}
</script>
